import { render, staticRenderFns } from "./EntityPage.vue?vue&type=template&id=6d56f797&scoped=true"
import script from "./EntityPage.vue?vue&type=script&lang=js"
export * from "./EntityPage.vue?vue&type=script&lang=js"
import style0 from "./EntityPage.vue?vue&type=style&index=0&id=6d56f797&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d56f797",
  null
  
)

export default component.exports